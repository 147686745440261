<template>
  <div id="broker-menus">
    <Nav />
    <Header />
    <Table />
    <HowToChoose />
    <HowWeRank />
    <Footer />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  created() {
    document.title = "BROKERS.MENU";
  },

  components: {
    Nav: () => import("../../components/broker-menus/Nav"),
    Header: () => import("../../components/broker-menus/Header"),
    Table: () => import("../../components/broker-menus/Table"),
    HowToChoose: () => import("../../components/broker-menus/HowToChoose"),
    HowWeRank: () => import("../../components/broker-menus/HowWeRank"),
    Footer: () => import("../../components/broker-menus/Footer"),
  },
};
</script>

<style>
</style>